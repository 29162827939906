<template>
	<div v-if="show">
		<div class="card">
			<div class="card-header card-header-flex pb-2">
				<div class="w-100 mt-2">
					<div class="row">
						<div class="col-8">
							<h5 class="mt-3 ml-0 mr-3 mb-2">
								<strong>
									<template v-if="operation === null">
                                        {{$t('title.autoInboundRules')}}
                                        <span v-if="pagination.total" >({{pagination.total}})</span>
                                    </template>
									<template v-else>{{ $t(operationTitle) }}</template>
								</strong>
							</h5>
						</div>
						<div class="col-4 text-right">
							<div v-if="operation === null">
								<div class="mt-3">
									<!-- Using components -->
									<b-input-group class="mt-3">
										<b-form-input type="search" class="form-control form-control-sm"
										              :placeholder="$t('input.whatAreYouLookingFor')"
										              v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
										<b-input-group-append>
											<b-button @click="setOperation('add')" variant="info" size="sm"
											          :title="$t('button.title.addNewItem')"
											          v-if="$global.hasPermission('autoinboundrulesstore')" v-b-tooltip.hover>
												<i class="fe fe-plus"></i> {{$t('button.addNew')}}
											</b-button>
											<b-button size="sm" :title="$t('button.title.filterRecords')"
											          variant="outline-info"
											          @click="filters.visible = !filters.visible" v-b-tooltip.hover
											          v-if="$global.hasPermission('autoinboundrulesview')">
												<i class="fa fa-filter"></i>
											</b-button>
											<b-button size="sm" :title="$t('button.title.resetList')"
											          variant="outline-info"
											          @click="handleResetFilterClick()" v-b-tooltip.hover>
												<i class="fa fa-refresh"></i>
											</b-button>
											<b-button size="sm" :title="$t('button.title.runCommand')"
											          variant="outline-danger"
                                                      :disabled="global.pendingRequests > 0"
											          @click="handleCommandRunClick" v-b-tooltip.hover>
                                                <clip-loader style="display: inline" :loading="true" color="#fff" size="12px"
                                                             v-if="global.pendingRequests > 0">
                                                </clip-loader>
                                                <template v-if="global.pendingRequests <= 0">
												    <i class="fa fa-send-o"></i>
                                                </template>
											</b-button>
										</b-input-group-append>
									</b-input-group>
								</div>
							</div>
							<div v-else>
								<b-button variant="warning" size="sm" class="mt-3"
								          @click="handleOperationClose()"
								          v-b-tooltip.hover :title="$t('button.title.cancel')">
									<i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
								</b-button>
							</div>
						</div>
					</div><!-- /.row -->
				</div><!-- /.w-100 -->
			</div><!-- /.card-header -->
			<div class="card-body">
                <div class="autoinboundrules-table">
                    <b-table hover responsive
                             ref="table"
                             :busy="listingLoading"
                             :items="dataSource"
                             :fields="columns"
                             :sort-by.sync="sortField"
                             @sort-changed="handleSortChange">
                        <template v-slot:cell(is_active)="record">
                            <b-badge v-show="record.item.is_active > 0" variant="success">{{ $t('msc.active') }}</b-badge>
                            <b-badge v-show="record.item.is_active <= 0">{{ $t('msc.de_active') }}</b-badge>
                        </template>
                        <template v-slot:cell(brands)="record">
                            <h6>
                                <b-badge v-for="(item, index) in record.item.brands" :key="index" variant="secondary" class="mr-2">
                                    <span class="text-white">
                                        <strong>{{item.title}}</strong>
                                    </span>
                                </b-badge>
                            </h6>
                        </template>
                        <template v-slot:cell(models)="record">
                            <h6>
                                <b-badge v-for="(item, index) in record.item.models" :key="index" variant="secondary" class="mr-2">
                                    <span class="text-white"><strong>{{item.title}}-{{item.version_code}}</strong></span>
                                </b-badge>
                            </h6>
                        </template>
                        <template v-slot:cell(from_locations)="record">
                            <div v-for="(item, index) in record.item.from_locations">
                                <print-ad :item="item" titled="1"></print-ad>
                            </div>
                        </template>
                        <template v-slot:cell(to_locations)="record">
                            <div v-for="(item, index) in record.item.to_locations">
                                <print-ad :item="item" titled="1"></print-ad>
                            </div>
                        </template>
                        <template v-slot:cell(supplier_carriers)="record">
                            <div v-for="(item, index) in record.item.supplier_carriers">
                                {{item.name}}
                            </div>
                        </template>
                        <template v-slot:cell(supplier_compound_id)="record">
                            {{ (record.item.supplier_compound || {}).name }}
                        </template>
                        <template v-slot:cell(action)="record">
                            <a @click="setOperation('edit', record.item.id)"
                               :title="$t('button.title.editItem')" v-if="$global.hasPermission('autoinboundrulesupdate')"
                               v-b-tooltip.hover>
                                <i class="fe fe-edit"></i>
                            </a>
                            <a-popconfirm title="Are you sure？" @confirm="handleDeleteClick(record.item.id)"
                                          v-if="$global.hasPermission('autoinboundrulesdestroy')">
                                <i slot="icon" class="fe fe-trash"></i>
                                <a class=" ml-1"
                                   :title="$t('button.title.deleteItem')"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-trash"></i>
                                </a>
                            </a-popconfirm>
                        </template>
                    </b-table><!-- /.b-table -->

                    <div class="clearfix">
                        <div class="float-left ">
                            <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                        </div>
                        <div class="float-right">
                            <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                            ></b-pagination>
                        </div><!-- /.pull-right -->
                    </div><!-- /.clearfix -->
                </div><!-- /.autoinboundrules-table -->
                <div class="autoinboundrules-operation">
                    <a-drawer
                        placement="right"
                        :width="'80%'"
                        :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                        :closable="false"
                        @close="handleOperationClose"
                        :visible="operation !== null && operation !== 'detail'"
                        :zIndex="10"
                        :title="$t(operationTitle)"
                    >
                        <form @submit.prevent="handleSubmit" autocomplete="off">
                            <b-row>
                                <b-col cols="12" v-show="global.pendingRequests > 0">
                                    <a-skeleton active :paragraph="{ rows: 10 }"/>
                                </b-col>
                                <b-col cols="12" v-show="global.pendingRequests <= 0">
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.brands')+' *'"
                                                label-for="brands"
                                                :invalid-feedback="formErrors.first('brands')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.brands"
                                                    placeholder=""
                                                    v-model="formFields.brands"
                                                    :class="[{'invalid is-invalid': (formErrors.has('brands'))}]"
                                                    @deselect="handleBrandDeSelect"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.models')+' *'"
                                                label-for="models"
                                                :invalid-feedback="formErrors.first('models')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="_.filter(dropdowns.models, (item) => _.includes(formFields.brands, item.brand_id))"
                                                    placeholder=""
                                                    v-model="formFields.models"
                                                    :class="[{'invalid is-invalid': (formErrors.has('brands'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.fromLocations')+' *'"
                                                label-for="from_locations"
                                                :invalid-feedback="formErrors.first('from_locations')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.locations"
                                                    placeholder=""
                                                    v-model="formFields.from_locations"
                                                    :class="[{'invalid is-invalid': (formErrors.has('from_locations'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.toLocations')+' *'"
                                                label-for="to_locations"
                                                :invalid-feedback="formErrors.first('to_locations')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.locations"
                                                    placeholder=""
                                                    v-model="formFields.to_locations"
                                                    :class="[{'invalid is-invalid': (formErrors.has('to_locations'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row><!--/b-row-->
                                    <b-row>
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.supplierCarriers')+' *'"
                                                label-for="supplier_carriers"
                                                :invalid-feedback="formErrors.first('supplier_carriers')">
                                                <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.supplier_carriers"
                                                    placeholder=""
                                                    v-model="formFields.supplier_carriers"
                                                    :class="[{'invalid is-invalid': (formErrors.has('supplier_carriers'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                        <b-col sm="6">
                                            <b-form-group
                                                :label="$t('input.supplierCompound')+' *'"
                                                label-for="supplier_compound_id"
                                                :invalid-feedback="formErrors.first('supplier_compound_id')">
                                                <treeselect
                                                    :multiple="false"
                                                    :options="dropdowns.supplier_compounds"
                                                    placeholder=""
                                                    v-model="formFields.supplier_compound_id"
                                                    :class="[{'invalid is-invalid': (formErrors.has('supplier_compound_id'))}]"
                                                />
                                            </b-form-group>
                                        </b-col><!--/b-col-->
                                    </b-row>
                                </b-col><!--/b-col-->
                                <div class="drawer-footer">
                                    <b-button
                                        size="sm"
                                        type="submit"
                                        variant="primary"
                                        :disabled="global.pendingRequests > 0"
                                        v-b-tooltip.hover :title="$t('button.title.save')"
                                    >
                                        <clip-loader style="display: inline" :loading="true" color="#fff"
                                                     size="12px"
                                                     v-if="global.pendingRequests > 0"></clip-loader>
                                        <i class="fa fa-save mr-1"></i>
                                        {{$t('button.save')}}
                                    </b-button>
                                    <b-button variant="warning" class="ml-3"
                                              size="sm" @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </b-row><!--/b-row-->
                        </form><!--/form-->
                    </a-drawer>
                </div><!--/.autoinboundrules-operation-->
				<div class="filter-container">
					<a-drawer
						placement="left"
						:width="'360px'"
						:wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
						:closable="false"
						@close="filters.visible = !filters.visible"
						:visible="!operation && filters.visible"
						:zIndex="10"
						:title="$t('title.advanceFilters')"
					>
						<form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
							<b-row>
								<b-col sm="12">
									<b-form-group
											:label="$t('input.fromAddedDate')"
											label-for="fromDate">
										<b-form-datepicker placeholder="" id="fromDate" v-model="filters.from_date"
										                   class="mb-2"></b-form-datepicker>
									</b-form-group>
								</b-col><!--/b-col-->
								<b-col sm="12">
									<b-form-group
											:label="$t('input.toAddedDate')"
											label-for="toDate">
										<b-form-datepicker placeholder="" id="toDate" v-model="filters.to_date"
										                   class="mb-2"></b-form-datepicker>
									</b-form-group>
								</b-col><!--/b-col-->
							</b-row>
							<div class="drawer-footer">
								<b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
								          class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
									{{$t('button.close')}}
								</b-button>
								<b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
								          :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
									{{$t('button.reset')}}
								</b-button>
								<b-button size='sm' variant="primary" button="submit" type="filled"
								          :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
									{{$t('button.apply')}}
								</b-button>
							</div><!-- /.drawer-footer -->
						</form>
					</a-drawer>
				</div><!-- /.filter-container -->
			</div><!-- /.card-body-->
		</div><!-- /.card -->
	</div>
</template>
<script>
    import ListingMixin from '../../util/ListingMixin'
    import Error from '../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'

    const FORM_STATE = {
        brands: null,
        models: null,
        from_locations: null,
        to_locations: null,
        supplier_carriers: null,
        supplier_compound_id: null,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.groupId'),
            key: 'group_id',
            sortable: true,
            sortKey: 'group_id',
        },
        {
            label: self.$t('column.brands'),
            key: 'brands',
            sortable: false,
        },
        {
            label: self.$t('column.models'),
            key: 'models',
            sortable: false,
        },
        {
            label: self.$t('column.fromLocations'),
            key: 'from_locations',
            sortable: false,
        },
        {
            label: self.$t('column.toLocations'),
            key: 'to_locations',
            sortable: false,
        },
        {
            label: self.$t('column.supplierCarriers'),
            key: 'supplier_carriers',
            sortable: false,
        },
        {
            label: self.$t('column.supplierCompound'),
            key: 'supplier_compound_id',
            sortable: true,
        },
        (self.$global.hasAnyPermission(['autoinboundrulesupdate', 'autoinboundrulesdestroy'])
        ? {
            label: self.$t('column.action'),
            class: 'text-right',
            key: 'action',
            width: 150,
        } : {}),
    ];

    export default {
        mixins: [ListingMixin],
        components: {
            Datepicker,
            Treeselect
        },
        data() {
            return {
                operationTitle: 'title.autoInboundRules',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'auto/inbound/rules',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    types: [],
                    supplier_carriers: [],
                    supplier_compounds: [],
                },
                show: true,
            }
        },
        mounted() {
            this.$title = this.$t('topBar.navigations.modules.autoInboundRules')
            this.getBrands()
            this.getModels()
            this.getSupplierCarriers()
            this.getSupplierCompounds()
            this.getLocations()

            if (this.$route.query && this.$route.query.operation && this.$route.query.oToken) {
                this.handleEditClick(this.$route.query.oToken)
            }
        },
        methods: {
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addAutoInboundRule' : 'title.editAutoInboundRule')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            async handleSubmit() {
                this.formErrors = new Error({})
                try {
                    const response = await request({
                        url: this.formFields.id ? 'auto/inbound/rules/update' : 'auto/inbound/rules/create',
                        method: 'post',
                        data: this.formFields,
                    })

                    if (this.formFields.id) {
                        this.itemUpdated()
                    } else {
                        this.itemAdded()
                    }

                    this.handleOperationClose()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleDeleteClick(id) {
                try {
                    const response = await request({
                        method: 'post',
                        url: '/auto/inbound/rules/delete',
                        data: {
                            id: id,
                        },
                    })
                    this.loadList(this.listQueryParams)
                    this.itemDeleted()
                } catch (errors) {
                    this.itemDeleteFails()
                }
            },
            async handleEditClick(id) {
                try {
                    const response = await request({
                        method: 'get',
                        url: `/auto/inbound/rules/detail/${id}`,
                    })
                    this.operationTitle = this.$t('title.editLogisticType')
                    const {data} = response
                    const {from_locations, to_locations, models, brands, supplier_carriers} = data

                    this.formFields = {
                        from_locations: _.map(from_locations, (item) => item.id),
                        to_locations: _.map(to_locations, (item) => item.id),
                        models: _.map(models, (item) => item.id),
                        brands: _.map(brands, (item) => item.id),
                        supplier_carriers: _.map(supplier_carriers, (item) => item.id),
                        supplier_compound_id: data.supplier_compound.id,
                        id: data.id,
                    }
                } catch (e) {
                    this.itemEditFails()
                    this.formFields = {...FORM_STATE}
                }
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data.map(item => ({...item, id: item.id, label: `${item.label} - ${item.version_code}`}))

                } catch (e) {
                    this.dropdowns.models = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data

                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplier_carriers = data

                } catch (e) {
                    this.dropdowns.supplier_carriers = []
                }
            },
            async getSupplierCompounds() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/compound',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplier_compounds = data

                } catch (e) {
                    this.dropdowns.supplier_compounds = []
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('autoinboundrulesview')
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            async handleCommandRunClick() {
                try {
                    const response = await request({
                        url: '/auto/inbound/rules/send',
                        method: "post"
                    })

                    const {data} = response

                    this.autoRunFinishedCheckLog()
                } catch (e) {
                    this.serverError()
                }
			},
			handleResetFilterClick() {
				this.filters = {...FILTER_STATE}
				this.isFilterApplied = 'reset'
				this.handleResetClick()
				this.loadList(this.listQueryParams)
			},
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
            handleBrandDeSelect() {
                this.formFields.models = null
            }
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
